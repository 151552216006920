<p-dialog [(visible)]="display" [style]="{width: '890px'}" header="{{header}}" [resizable]="false" position="top">
<!--<h4>{{header}}</h4>-->
  <ng-container *ngIf="notification">
    <div [innerHtml]="notification['detailText']|safeHtml"></div>
  </ng-container>

  <p-footer>
    <p-button *ngIf="displayUpdate" label="{{ 'Update'|translate }}" icon="pi pi-pencil" styleClass="p-button-raised p-button-warning" (onClick)="doUpdate()"></p-button>
    <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="markViewed()"></p-button>
  </p-footer>
</p-dialog>

<p-dialog header="HỢP ĐỒNG CUNG CẤP DỊCH VỤ HOÁ ĐƠN ĐIỆN TỬ" [(visible)]="displayDialog['case-1']" [style]="{width: '890px'}" position="top" styleClass="no-scroll">
  <div *ngIf="case1Url" style="height: 70vh">
    <iframe [src]="case1Url | safeResourceUrl" width="100%" height="100%"></iframe>
  </div>
  <p-footer>
    <ng-container *ngIf="ci?.agreementSigned === 'Y'">
      <p-message severity="success" text="Đã ký hợp đồng" styleClass="p-mt-2 p-mr-2"></p-message>
    </ng-container>
    <ng-container *ngIf="ci?.agreementSigned === 'N'">
      <p-button label="Chỉnh sửa thông tin" icon="pi pi-pencil" styleClass="p-button-raised p-button-warning" (onClick)="showCiForm()"></p-button>
      <p-button label="Ký hợp đồng" icon="pi pi-file-edit" styleClass="p-button-raised p-button-success" (onClick)="signCase1()"></p-button>
    </ng-container>
    <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayDialog['case-1']=false"></p-button>
  </p-footer>
</p-dialog>

<form [formGroup]="ciForm">
  <p-dialog header="Thông tin bên A" [(visible)]="displayCiForm" [style]="{width: '50rem'}" [resizable]="false" position="top">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="otherPartyAddr">{{ 'Address'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="otherPartyAddr" formControlName="otherPartyAddr">
      </div>
      <div class="p-field p-col-12 p-md-6 p-pr-3">
        <label for="otherRepName">{{ 'RepresentativeName'|translate }}<span class="required">*</span></label>
        <input pInputText type="text" id="otherRepName" formControlName="otherRepName">
      </div>
      <div class="p-field p-col-12 p-md-6 p-pl-3">
        <label for="otherRepJobTitle">{{ 'RepresentativeJobTitle'|translate }}</label>
        <input pInputText type="text" id="otherRepJobTitle" formControlName="otherRepJobTitle">
      </div>
      <div class="p-field p-col-12 p-md-6 p-pr-3">
        <label for="otherEmailAddress">{{ 'Email'|translate }}</label>
        <input pInputText type="email" id="otherEmailAddress" formControlName="otherEmailAddress" [email]="true">
      </div>
      <div class="p-field p-col-12 p-md-6 p-pl-3">
        <label for="otherPhoneNumber">{{ 'Phone'|translate }}</label>
        <input id="otherPhoneNumber" pInputText type="text" formControlName="otherPhoneNumber" [maxlength]="20">
      </div>
      <div class="p-field p-col-12 p-md-6 p-pr-3">
        <label for="otherAccountNumber">Số tài khoản</label>
        <input pInputText type="email" id="otherAccountNumber" formControlName="otherAccountNumber">
      </div>
      <div class="p-field p-col-12 p-md-6 p-pl-3">
        <label for="otherBankName">Ngân hàng</label>
        <input id="otherBankName" pInputText type="text" formControlName="otherBankName">
      </div>
    </div>
    <p-footer>
      <p-button label="{{ 'Save'|translate }}" icon="pi pi-file-edit" styleClass="p-button-raised" (onClick)="submitCiForm()"></p-button>
      <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayCiForm=false"></p-button>
    </p-footer>
  </p-dialog>
</form>
<form [formGroup]="customerForm">
  <p-dialog header="Cập nhật thông tin" [(visible)]="displayForm" [style]="{width: '50rem'}" [resizable]="false" position="top">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="partyName">{{ 'Tên khách hàng'|translate }}<span class="required">*</span></label>
        <input pInputText disabled type="text" id="partyName" formControlName="partyName">
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="pseudoId">{{ 'Mã số thuế'|translate }}<span class="required">*</span></label>
        <input pInputText disabled type="text" id="pseudoId" formControlName="pseudoId">
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="emailAddress">{{ 'Email'|translate }}</label>
        <input pInputText type="text" id="emailAddress" formControlName="emailAddress">
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="contactNumber">{{ 'Số  điện thoại'|translate }}</label>
        <input pInputText type="text" id="contactNumber" formControlName="contactNumber">
      </div>


    </div>
    <p-footer>
      <p-button label="{{ 'Save'|translate }}" icon="pi pi-file-edit" styleClass="p-button-raised" (onClick)="submitForm()"></p-button>
      <p-button label="{{ 'Close'|translate }}" icon="pi pi-times" styleClass="p-button-raised p-button-text p-button-plain" (onClick)="displayForm=false"></p-button>
    </p-footer>
  </p-dialog>
</form>
